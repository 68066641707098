import { initializeApp } from "firebase/app";
import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import db from "./firebase";
import { AuthContextProvider, useAuthState, useUserID } from "./firebase";
import { getAuth, signOut } from "firebase/auth";
import logo from './images/logo.png';
import { Line } from 'react-chartjs-2';
import DateTimePicker from 'react-datetime-picker';
import { Form, Button, Card, Alert } from "react-bootstrap"
import './styles.css';
import './App.css';

var arrayHeader = ["timestamp","redRaw","greenRaw","blueRaw"];
var arrayMetricHeader = ["timestamp","heartrate","prv","peakToPeakMs","flowActivity","pssScore"];
var deviceVersion = "2022";
let arrayData = [];
let arrayMetricData = [];
let arrayData10mm = [];
let arrayData27mm = [];

export const Home = () => {
  
  var timeData = [];
  var dataRed = [];
  var dataGreen = [];
  var dataBlue = []; 

  var labelsData = [];
  var labelsMetricData = [];

  var timeMetricData = [];
  var prvData = [];
  var hrData = [];
  var peakToPeakData = [];
  var pssScoreData = [];
  var flowActivityData = [];
  var imageUrlData = [];

  var { updatedID } = useUserID();
  var userID = "8roZdbyCLYOXh2VuuEwXGbRAM9b2";
  var deviceID = "EDDAF489-D9C4-4CC5-88EB-020C2BF53A1E";

  const [startTime, onChangeStart] = useState(new Date(Date.now() - 1000 * 60 * 30)); //default to last 30 minutes of data
  const [endTime, onChangeEnd] = useState(new Date());
  const [show, setShow] = useState(false);

  const updateDeviceIDValue = useCallback(async (e) => {
    e.preventDefault();

    const [id] = e.target.elements;
    if (id != ""){
      deviceID = id.value;
      console.log(deviceID);
    }
    getTimeQueryData();
    
  }, []);

  const [chartDataRaw, setChartDataRaw] =  React.useState({
   labels: labelsData,
   datasets: [
    {
      data:[],
      label: 'Red',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Green',
      backgroundColor: 'rgba(99, 200, 50, 0.5)',
      borderColor: 'rgb(99, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Blue',
      backgroundColor: 'rgba(50, 99, 200, 0.5)',
      borderColor: 'rgb(50, 99, 200)',
      fill: false,
      strokeWidth: 2
    }
   ] 
  })

  const [chartMetricData, setChartMetricData] = React.useState({
   labels: labelsMetricData,
   datasets: [
    {
      data:[],
      label: 'Flow Activity (%)',
      backgroundColor: 'rgba(200, 50, 200, 0.5)',
      borderColor: 'rgb(200, 50, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'PRV SDNN',
      backgroundColor: 'rgba(50, 99, 200, 0.5)',
      borderColor: 'rgb(50, 99, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: false
    },
    {
      data:[],
      label: 'Heart rate BPM',
      backgroundColor: 'rgba(200, 50, 50, 0.5)',
      borderColor: 'rgb(200, 50, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: false
    },
    {
      data:[],
      label: 'Peak to peak (millis)',
      backgroundColor: 'rgba(200, 200, 50, 0.5)',
      borderColor: 'rgb(200, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'PSS Score',
      backgroundColor: 'rgba(50, 200, 50, 0.5)',
      borderColor: 'rgb(50, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    }
   ]
  })

  const getTimeQueryData = () => {

    if (document.getElementById('deviceId').value != ""){
      deviceID = document.getElementById('deviceId').value;
    }

    clear_data();

    var millisStart = startTime.getTime()/1000.0
    var millisEnd = endTime.getTime()/1000.0
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }
    
    console.log("query uid: " + deviceID);

    var data = db.collection("heartRateSessionDataByID/"+deviceID+"/sessionData").where('timestampServer', '>=', millisStart).where('timestampServer', '<=', millisEnd).orderBy('timestampServer', 'desc');
    data.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            timeData.push(...doc.data()["timestampEdge"].reverse());
            dataRed.push(...doc.data()["rawRedData"].reverse());
            dataGreen.push(...doc.data()["rawGreenData"].reverse());
            dataBlue.push(...doc.data()["rawBlueData"].reverse());
            hrData.push(doc.data()["heartrate"]);
            prvData.push(doc.data()["prv"]);
            peakToPeakData.push(doc.data()["peakToPeakMs"]);
            pssScoreData.push(doc.data()["pssScore"]);
            flowActivityData.push(doc.data()["flowActivity"]);
            labelsMetricData.push(millisToMinutesAndSeconds(doc.data()["timestampServer"]*1000.0));
            timeMetricData.push(doc.data()["timestampServer"]*1000.0);
            imageUrlData.push(doc.data()["imageShownURL"]);
        });

        let tLen = timeData.length;
        for (let i = 0; i < tLen; i++) {
          if (dataGreen.length > 0){
            arrayData.push([timeData[i],dataRed[i],dataGreen[i],dataBlue[i]]);
          }
          labelsData.push(millisToMinutesAndSeconds(timeData[i]));
        }

        let mLen = timeMetricData.length;
        for (let i = 0; i < mLen; i++) {
          arrayMetricData.push([timeMetricData[i],hrData[i],prvData[i],peakToPeakData[i],flowActivityData[i],pssScoreData[i]]);
        }

        //csv data ordering
        arrayData.reverse();
        arrayMetricData.reverse();

        //chart data ordering
        labelsData.reverse();
        dataRed.reverse();
        dataGreen.reverse();
        dataBlue.reverse();

        labelsMetricData.reverse();
        hrData.reverse();
        prvData.reverse();
        peakToPeakData.reverse();
        pssScoreData.reverse();
        flowActivityData.reverse();
        imageUrlData.reverse();

        refreshChartRaw();
        refreshMetricChart();
        refreshImageRow();

    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

  }

  const clear_data = () => {
      arrayData = [];
      arrayMetricData = []
      timeData = [];
      labelsData = [];
      dataRed = [];
      dataGreen = [];
      dataBlue = [];
      timeMetricData = [];
      labelsMetricData = [];
      prvData = [];
      hrData = [];
      peakToPeakData = [];
      pssScoreData = [];
      flowActivityData = [];
      imageUrlData = [];
      // console.log('data cleared prior to query');
  }
  
  useEffect(() => { 
    //wait for login to persist
    setTimeout(function () {
        getTimeQueryData()
    }, 1000);
  }, []);

  const export_csv = () => {
    console.log(arrayData)
    let header = arrayHeader.join(",") + '\n';
    let csv = header;
    arrayData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_raw_data.csv';
    hiddenElement.click();
    console.log("raw - csv saved v2022");
  };

  const export_metric_csv = () => {
    console.log(arrayData)
    let header = arrayMetricHeader.join(",") + '\n';
    let csv = header;
    arrayMetricData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_metric_data.csv';
    hiddenElement.click();
    console.log("metric - csv saved v2022");
  };

  const millisToMinutesAndSeconds = (millis) => {
    //time is in GMT
    var gmtDate = new Date(millis);

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    
    return hours + ":" + minutes + ":" + seconds;
  }

  const secondsToMinutesAndSeconds = (totalSeconds) => {
    //time is in GMT
    var gmtDate = new Date(totalSeconds*1000);
    var localDate = gmtDate.toLocaleString();

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  const refreshImageRow = () => {
      // console.log(imageUrlData);
      var myDiv = document.getElementById("imageRow");
      myDiv.innerHTML = "";
      for (let i = 0; i < imageUrlData.length; i++) {
        // console.log(imageUrlData[i]);
        var imageUrl = imageUrlData[i];
        var elem = document.createElement("generatedImage");
        elem.innerHTML += '<img src="'+imageUrl+'" height="100%" width="100%" />';
        document.getElementById("imageRow").appendChild(elem);
      }
      
  };

  const refreshChartRaw = () => {

        setChartDataRaw({
                      ...chartDataRaw,
                      labels: labelsData,
                      datasets:[
                          {
                              ...chartDataRaw.datasets[0],
                              data: dataRed
                          },
                          {
                              ...chartDataRaw.datasets[1],
                              data: dataGreen
                          },
                          {
                              ...chartDataRaw.datasets[2],
                              data: dataBlue
                          }
                      ]})

  };

  const refreshMetricChart = () => {

        setChartMetricData({
                      ...chartMetricData,
                      labels: labelsMetricData,
                      datasets:[
                          {
                              ...chartMetricData.datasets[0],
                              data: flowActivityData
                          },
                          {
                              ...chartMetricData.datasets[1],
                              data: prvData
                          },
                          {
                              ...chartMetricData.datasets[2],
                              data: hrData
                          },
                          {
                              ...chartMetricData.datasets[3],
                              data: peakToPeakData
                          },
                          {
                              ...chartMetricData.datasets[4],
                              data: pssScoreData
                          }
                      ]})
  };


  const AlertDismissible = () => {
      return (
        <>
          <Alert show={show} variant="success">
            <Alert.Heading>Request alert limit!</Alert.Heading>
            <p>
              Presently we only allow a maximum period of 24 hours per request, please change your search to 24 hours or less
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <button onClick={() => setShow(false)} variant="outline-success">
                Close
              </button>
            </div>
          </Alert>
        </>
      );
  };
  
  return (
    <div className="app">
      <div className="App__DataDisplay">
        <AlertDismissible />
        <div className="header">
          <a href="https://www.blueberryx.com/" target="_blank"><img className="logo" src={logo} alt="logo"/></a>
          <div className="header-right">
            <DateTimePicker onChange={onChangeStart} value={startTime} />
            <DateTimePicker onChange={onChangeEnd} value={endTime} />
            <button className="blueButton" onClick={getTimeQueryData}>Get Data</button>
            <button className="blueButton" onClick={export_csv}>Download raw CSV</button>
            <button className="blueButton" onClick={export_metric_csv}>Download Metric CSV</button>
            <button className="whiteButton" onClick={() => signOut(getAuth())}>Log out</button>
          </div>
        </div>
        <div className="row">
          <form onSubmit={updateDeviceIDValue}>
              <div className="header">
                <div className="header-right">
                  <input id="deviceId" name="deviceId" className="input-field" placeholder="EDDAF489-D9C4-4CC5-88EB-020C2BF53A1E" width={window.innerWidth < 760 ? 270 : 500} required/>
                  <button className="blueButton" type="submit">Update ID</button>
                </div>
              </div>
          </form>
        </div>
        <div className="top-row">
          <div id="imageRow" className="image-row">
          </div>
        </div>
        <div>
          <Line data={chartDataRaw} width={window.innerWidth < 760 ? 270 : 400} height={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true, 
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartMetricData} width={window.innerWidth < 760 ? 270 : 400} style={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true,
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            }, 
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          
      </div>
      </div>
    </div>
  );
};
