import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useCallback, useEffect } from "react";
import { useAuthState } from "./firebase";
import { Form, Button, Card, Alert } from "react-bootstrap"
import logo from './images/logo.png';
import './styles.css';
import './App.css';

export const Login = () => {

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    // const [email, password] = e.target.elements;
    const auth = getAuth();
    const email = "us@blueberryx.com"
    const password = "M^s^:<o,fSk7$+h?"

    //console.log(email.value, password.value);
    try {
      await signInWithEmailAndPassword(auth, "us@blueberryx.com", "M^s^:<o,fSk7$+h?");
      // console.log("login success");
    } catch (e) {
      alert(e.message);
    }
  }, []);

  return (
    <div>
      <Card>
        <Card.Body className="App-header">
          <img src={logo} className="logo-login" alt="Blueberry logo" />
          <div className="card">
            <form onSubmit={handleSubmit} className="card-form">
              <div className="action">
                <button className="action-button" type="submit">Access</button>
              </div>
            </form>
            <div className="card-info">
              <a href="https://blueberryx.com/pages/privacy-policy" target="_blank">Privacy policy</a>
              <p>Blueberry © 2022 - 🫐</p>
            </div>
          </div>
          </Card.Body>
      </Card>
    </div>
  );
};
